import { lazy, type ReactNode, type ReactElement, type ComponentProps, Suspense } from "react";
import { Routes, Route, Navigate, Outlet, useMatch } from "react-router-dom";

import { EasylinkTypes } from "graphql_globals";
import { mortgageDashboardDeserializer } from "common/mortgage/transactions/dashboard/filter";
import APP from "constants/applications";
import SessionExpiredModal from "common/authorization/session_expired_modal";
import { useIAnav, useTxnDetailsRedesign } from "util/feature_detection";
import MeetingSimulator from "common/meeting_simulator";
import { composeViewerWrappers, createViewerManager } from "util/viewer_wrapper";
import { useIsAuthenticated, HardLoginRedirect } from "common/authentication";
import { MfaSetupBlocker } from "common/authentication/mfa_setup_blocker";
import { TitleRedirectChecker } from "common/authorization";
import { TIER_PATH } from "constants/tier";
import {
  TitleAccountOnboarding,
  TitleAccountOnboardingWrapper,
} from "title_portal/account/account_onboarding";
import App from "common/app";
import TargetAuthLinkHandler from "common/target_auth_link_handler";
import TierBlocker from "common/tier/blocker";
import { SigningContextWrapper, useMeetingIdParam } from "common/signing_context";
import OrganizationRoleContext from "common/organization/organization_role_context";
import VerifyEnabled from "common/organization/authorization/verify_enabled";
import { OrganizationFeatureGatingSpecialization } from "common/feature_gating/specialization";
import TitleAgencyAppFrame from "title_portal/app_frame";
import Logger from "common/logger";
import TermsOfServiceBlocking from "common/modals/terms_of_service/blocking";
import ExportModal from "common/transactions/transaction_v2/export";
import TitleAccess from "title_portal/access";
import TitleAccessTransactionDetails from "title_portal/access/transaction/details";
import ResetPassword from "common/account/login/reset_password";
import SignupV2 from "common/account/signup_v2";
import Activation from "title_portal/account/activation";
import VerifyEmail from "common/account/verify_email";
import TierUpgrade from "common/tier/upgrade";
import ShortcutCheatsheet from "common/shortcut_cheatsheet";
import TitleAgencySetup from "title_portal/organization/setup";
import AudioVideoSettings from "common/meeting/audio_video_settings";
import { NotaryQueueProvider } from "common/notary/queue";
import { OnlineHeartBeatUnavailable } from "common/notary/online_heartbeat";
import Meeting from "common/mortgage/meeting";
import MeetingCompleted from "common/meeting/completed";
import BusinessEmployeeTransactions from "common/employees/transactions";
import { makeUserManagementRoutes } from "common/organization/member_management/routing";
import { SummaryContainer } from "common/details/summary";
import IdentityDetails from "common/details/identity";
import { DeprecatedNotaryDetailsContainer } from "common/details/meeting/notary_details/deprecated/deprecated_index";
import { DeprecatedWitness } from "common/details/meeting/witness_details/deprecated_index";
import { DeprecatedVideoContainer } from "common/details/meeting/videos/deprecated/deprecated_index";
import TransactionDetailsBundle from "common/details/bundle";
import History from "common/details/history";
import InstructionDetails from "common/details/instructions";
import PointsOfContactDetails from "common/details/recipients/points_of_contact";
import TransactionDetailsContainer from "common/transactions/details";
import { OrganizationTemplates } from "common/organization_templates";
import OrganizationTemplatePrepare from "common/organization_templates/prepare";
import OrganizationTemplateEdit from "common/organization_templates/edit";
import OrganizationTemplateTools from "common/organization_templates/tools";
import TitleTransactions from "title_portal/transactions";
import InvalidPaymentBlocker, { InvalidPaymentRedirect } from "common/configured/invalid_payment";
import TransactionEmployeeDetails from "common/transactions/employee_details";
import ConfiguredRedirect from "common/configured";
import ConfiguredEligibilitiesRedirect from "common/configured/eligibilities";
import SetupTransactionV2 from "title_portal/transactions/setup/v2";
import TransactionEditDetailsContainer from "title_portal/transactions/edit/container";
import { TitleGetStarted } from "common/get_started/title";
import { useMatchManyFirstPathParts } from "util/location";
import { NavigateWithSearchPreservation } from "util/navigation";
import ActivityLogs from "common/transactions/activity_logs";
import TransactionSubmitted from "common/transactions/transaction_submitted";
import VerifyEmailV2 from "title_portal/sign_up/verify_email";
import SetPassword from "title_portal/sign_up/set_password";
import Settings from "common/settingsv2";
import NewTransaction from "common/transactions/new";
import TransactionEditContainer from "common/transactions/transaction_v2";
import LoadingIndicator from "common/core/loading_indicator";
import ProofFrame from "common/proof_frame";
import { EASYLINK_DASHBOARD_PATH, TEMPLATES_DASHBOARD_PATH, TRANSACTION_PATH } from "util/routes";
import { GET_STARTED_PATH } from "common/proof_frame/path";
import { CLOSING_AGENDA_PATH } from "common/closing_agenda/path";
import {
  type OrganizationPermissions,
  PermissionRedirect as BasePermissionRedirect,
} from "common/core/current_user_role";
import { makeSetActiveOrgAndRedirectRoute } from "common/account/active_organization/routing";
import { STEPS } from "common/onboarding/steps";
import { EasylinkManager } from "common/dashboard/easy_link";
import { EasylinkCreateForm } from "common/dashboard/easy_link/create";
import { EasylinkEditForm } from "common/dashboard/easy_link/edit";
import { RecipientColorProvider } from "common/pdf/recipient_colors/context";
import { RecipientsContainer } from "common/details/recipients";
import { MeetingContainer } from "common/details/meeting";

import ViewerQuery, { type TitleRouterViewer_viewer as Viewer } from "./viewer_query.graphql";
import { RedirectRoot } from "./redirect_root";

const ClosingAgenda = lazy(() => import("title_portal/closing_agenda"));
const MeetingSuccess = lazy(() => import("common/meeting_success"));
const RealEstateNotaryMeeting = lazy(() => import("common/mortgage/notary_meeting"));
const TransactionCreationForm = lazy(() => import("common/transaction_creation/v3/form"));

const ENTRY = APP.TITLE_AGENCY;
const MOBILE_ROUTES = new Set(["reset_password", "verify-account", "meeting", "meeting/*"]);
const ViewerManager = createViewerManager<Viewer>(ViewerQuery, []);
export const DefaultWrapperWithoutFrame = composeViewerWrappers<Viewer>(
  TermsOfServiceBlocking,
  TitleRedirectChecker,
  VerifyEnabled,
  TierBlocker,
  OrganizationRoleContext,
  RecipientColorProvider,
  OrganizationFeatureGatingSpecialization,
  Logger,
  MfaSetupBlocker,
);
const MeetingWrapper = composeViewerWrappers<Viewer>(
  TermsOfServiceBlocking,
  TitleRedirectChecker,
  VerifyEnabled,
  TierBlocker,
  OrganizationRoleContext,
  RecipientColorProvider,
  OrganizationFeatureGatingSpecialization,
  Logger,
  MfaSetupBlocker,
);

const Frame = (props: ComponentProps<typeof TitleAgencyAppFrame>) =>
  useIAnav() ? (
    <ProofFrame portal="title">{props.children}</ProofFrame>
  ) : (
    <TitleAgencyAppFrame {...props} />
  );

function DefaultWrapper({
  children,
  withoutFrame,
}: {
  children?: ReactElement;
  withoutFrame?: boolean;
}) {
  return withoutFrame ? (
    <DefaultWrapperWithoutFrame>{children || <Outlet />}</DefaultWrapperWithoutFrame>
  ) : (
    <DefaultWrapperWithoutFrame>
      <NotaryQueueProvider>
        <Frame>{children || <Outlet />}</Frame>
      </NotaryQueueProvider>
    </DefaultWrapperWithoutFrame>
  );
}

/* Use as Route element where possible so that parent element stays the same between routes, ensuring single page navigation and preventing unneccessary refresh. Additionally, the <Suspense/> below will keep the nav/sidebar mounted and only put up a loading indicator for the main content when lazy loading. */
function RouteWrapper({
  children,
  withoutFrame,
}: {
  children?: ReactElement;
  withoutFrame?: boolean;
}) {
  return (
    <DefaultWrapper withoutFrame={withoutFrame}>
      <Suspense fallback={<LoadingIndicator />}>{children || <Outlet />}</Suspense>
    </DefaultWrapper>
  );
}

function PermissionRedirect({
  permissions,
  children,
}: {
  permissions: OrganizationPermissions[];
  children?: ReactNode;
}) {
  return (
    <BasePermissionRedirect permissions={permissions} redirectRoute={<RedirectRoot />}>
      {children}
    </BasePermissionRedirect>
  );
}

function TransactionDetailsRoutes({ isTxnDetailsRedesign }: { isTxnDetailsRedesign: boolean }) {
  return (
    <TransactionDetailsContainer withNotes withActivityLog>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {(props: any) => (
        <Routes>
          <Route path="summary" element={<SummaryContainer {...props} entry={ENTRY} />} />
          <Route
            path="contacts"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../user" replace />
              ) : (
                <PointsOfContactDetails {...props} />
              )
            }
          />
          <Route
            path="user"
            element={
              <PermissionRedirect permissions={["viewTransactionSigners"]}>
                <RecipientsContainer {...props} />
              </PermissionRedirect>
            }
          />
          {isTxnDetailsRedesign && (
            <Route path="meeting" element={<MeetingContainer {...props} />} />
          )}
          <Route
            path="identity"
            element={
              <PermissionRedirect permissions={["viewTransactionSignersIdentity"]}>
                <IdentityDetails {...props} />
              </PermissionRedirect>
            }
          />
          <Route
            path="notary"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../meeting" />
              ) : (
                <DeprecatedNotaryDetailsContainer {...props} />
              )
            }
          />
          <Route
            path="witness"
            element={
              isTxnDetailsRedesign ? <Navigate to="../meeting" /> : <DeprecatedWitness {...props} />
            }
          />
          <Route path="document" element={<TransactionDetailsBundle {...props} />} />
          <Route
            path="video"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../meeting" />
              ) : (
                <DeprecatedVideoContainer {...props} />
              )
            }
          />
          <Route path="history" element={<History {...props} />} />
          <Route path="notes" element={<InstructionDetails {...props} />} />
          <Route path="activity" element={<ActivityLogs {...props} />} />
          <Route path="*" element={<Navigate replace to="summary" />} />
        </Routes>
      )}
    </TransactionDetailsContainer>
  );
}

const UNAUTH_ROUTES = (
  <>
    <Route path="/reset_password/:token" element={<ResetPassword />} />

    <Route path="/signup">
      <Route
        path={TIER_PATH.TITLE_AGENT}
        element={<Navigate replace to={`/signup/${TIER_PATH.TITLE_PRO_ODN}`} />}
      />
      <Route
        path={TIER_PATH.TITLE_PRO_ODN}
        element={
          <SignupV2 tierPath={TIER_PATH.TITLE_PRO_ODN} selectedAccount={ENTRY} entry={ENTRY} />
        }
      />
      <Route
        path={TIER_PATH.TITLE_PRO_IHN}
        element={
          <SignupV2 tierPath={TIER_PATH.TITLE_PRO_IHN} selectedAccount={ENTRY} entry={ENTRY} />
        }
      />
      <Route
        path={TIER_PATH.TITLE_AGENCY_PRO_NOTARY}
        element={<Navigate replace to={`/signup/${TIER_PATH.TITLE_PRO_IHN}`} />}
      />
      <Route index element={<Navigate to={TIER_PATH.TITLE_PRO_ODN} />} />
      <Route path="*" element={<Navigate replace to={`/signup/${TIER_PATH.TITLE_PRO_ODN}`} />} />
    </Route>

    <Route path="*" element={<HardLoginRedirect />} />
  </>
);

const authRoutes = (transactionDetailsRedesignFlag: boolean) => {
  return (
    <>
      {/* needed to prevent redirecting to default route in AUTH_ROUTES after signup */}
      <Route path="/signup/*" element={<LoadingIndicator />} />

      <Route
        path="/access/transaction/:transactionID"
        element={<TitleAccessTransactionDetails />}
      />

      <Route
        path="/setup"
        element={
          <RouteWrapper withoutFrame>
            <PermissionRedirect permissions={["editOrganizationDetails"]}>
              <TitleAgencySetup />
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/onboarding"
        element={
          <RouteWrapper withoutFrame>
            <PermissionRedirect permissions={["editOrganizationDetails"]} />
          </RouteWrapper>
        }
      >
        <Route path=":step" element={<TitleAccountOnboarding />} />
        <Route index element={<Navigate to={STEPS.ELIGIBILITY} />} />
      </Route>

      <Route
        path={GET_STARTED_PATH}
        element={
          <RouteWrapper>
            <TitleAccountOnboardingWrapper>
              <PermissionRedirect
                permissions={["sendOrganizationTransactions", "viewEasyLinks", "manageTemplates"]}
              >
                <TitleGetStarted />
              </PermissionRedirect>
            </TitleAccountOnboardingWrapper>
          </RouteWrapper>
        }
      />

      <Route
        path="/notary-meeting/:meetingId"
        element={
          <RouteWrapper withoutFrame>
            <OnlineHeartBeatUnavailable>
              <PermissionRedirect permissions={["viewMeetings"]}>
                <SigningContextWrapper useSiginingContextId={useMeetingIdParam}>
                  <RealEstateNotaryMeeting />
                </SigningContextWrapper>
              </PermissionRedirect>
            </OnlineHeartBeatUnavailable>
          </RouteWrapper>
        }
      />

      <Route
        path="/meeting-success/:meetingId"
        element={
          <RouteWrapper withoutFrame>
            <OnlineHeartBeatUnavailable>
              <PermissionRedirect permissions={["viewMeetings"]}>
                <MeetingSuccess defaultReturnPath={CLOSING_AGENDA_PATH} />
              </PermissionRedirect>
            </OnlineHeartBeatUnavailable>
          </RouteWrapper>
        }
      />

      <Route
        path="/meeting/:meetingId"
        element={
          <MeetingWrapper>
            <AudioVideoSettings>
              <PermissionRedirect permissions={["viewMeetings"]}>
                <Meeting />
              </PermissionRedirect>
            </AudioVideoSettings>
          </MeetingWrapper>
        }
      />

      <Route
        path="/simulated-meeting/:meetingId"
        element={
          <RouteWrapper withoutFrame>
            <PermissionRedirect permissions={["viewMeetings"]}>
              <MeetingSimulator />
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/meeting/completed"
        element={
          <RouteWrapper withoutFrame>
            <PermissionRedirect permissions={["viewMeetings"]}>
              <MeetingCompleted />
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/settings/*"
        element={
          <RouteWrapper>
            <PermissionRedirect permissions={["viewOrganizationDetails"]}>
              <TitleAccountOnboardingWrapper>
                <Settings />
              </TitleAccountOnboardingWrapper>
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      {makeUserManagementRoutes({
        wrapper: (
          <RouteWrapper>
            <PermissionRedirect permissions={["viewTeamDetails"]}>
              <TitleAccountOnboardingWrapper />
            </PermissionRedirect>
          </RouteWrapper>
        ),
      })}

      <Route
        path="/employee-transactions/:organizationId/:memberUserId"
        element={
          <RouteWrapper>
            <TitleAccountOnboardingWrapper>
              <PermissionRedirect
                permissions={["viewOrganizationDetails", "viewOrganizationTransactions"]}
              >
                <BusinessEmployeeTransactions />
              </PermissionRedirect>
            </TitleAccountOnboardingWrapper>
          </RouteWrapper>
        }
      />
      {/* remove this with ia-nav as part of REAL-8360 */}
      <Route
        path="/templates"
        element={
          <RouteWrapper>
            <PermissionRedirect permissions={["manageTitleTemplates"]} />
          </RouteWrapper>
        }
      >
        <Route index element={<OrganizationTemplates />} />
        <Route path=":templateId/new" element={<OrganizationTemplateEdit isMortgage />} />
        <Route path=":templateId/edit" element={<OrganizationTemplateEdit isMortgage />} />
        <Route path=":templateId/tools" element={<OrganizationTemplateTools />} />
        <Route path=":globalID" element={<OrganizationTemplatePrepare />} />
      </Route>

      <Route
        path="/transaction-success"
        element={
          <RouteWrapper>
            <TransactionSubmitted />
          </RouteWrapper>
        }
      />

      <Route
        path="/transaction"
        element={
          <RouteWrapper>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]} />
          </RouteWrapper>
        }
      >
        <Route index element={<Navigate replace to={TRANSACTION_PATH} />} />
        <Route path="new" element={<Navigate to="/transaction/setup" />} />
        <Route
          path="create"
          element={
            <PermissionRedirect permissions={["createOrganizationTransactions"]}>
              <NewTransaction />
            </PermissionRedirect>
          }
        />
        <Route path="esign/:transactionId" element={<TransactionEditContainer />} />
        <Route path="proof/:transactionId" element={<TransactionEditContainer />} />
        {/* Can remove "closing-agenda" after notary-meeting-consolidation removal */}
        <Route path="closing-agenda" element={<ClosingAgenda />}>
          <Route path=":filter" element={null} />
        </Route>
        <Route
          element={
            <ConfiguredRedirect>
              <InvalidPaymentRedirect />
            </ConfiguredRedirect>
          }
        >
          <Route path="setup">
            <Route
              index
              element={
                <PermissionRedirect permissions={["createOrganizationTransactions"]}>
                  <ConfiguredEligibilitiesRedirect>
                    <SetupTransactionV2 />
                  </ConfiguredEligibilitiesRedirect>
                </PermissionRedirect>
              }
            />
            <Route
              path="v2"
              element={
                <PermissionRedirect permissions={["createOrganizationTransactions"]}>
                  <ConfiguredEligibilitiesRedirect>
                    <SetupTransactionV2 />
                  </ConfiguredEligibilitiesRedirect>
                </PermissionRedirect>
              }
            />
          </Route>
          <Route
            path="place-an-order"
            element={
              <PermissionRedirect permissions={["createOrganizationTransactions"]}>
                <ConfiguredEligibilitiesRedirect>
                  <SetupTransactionV2 />
                </ConfiguredEligibilitiesRedirect>
              </PermissionRedirect>
            }
          />
          <Route path="edit/:transactionId">
            <Route index element={<TransactionEditDetailsContainer />} />
            {/* Do not delete - email links depend on this */}
            <Route path="details" element={<Navigate to=".." replace />} />
          </Route>
        </Route>
        {/* end InvalidPaymentBlocker/ConfiguredRedirect grouping */}
        <Route path=":transactionId" element={<TransactionCreationForm />} />
      </Route>
      {/* end /transaction */}
      <Route path="/" element={<RouteWrapper />}>
        <Route index element={<RedirectRoot />} />
        <Route
          path={TRANSACTION_PATH}
          element={
            <TitleAccountOnboardingWrapper>
              <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
                <InvalidPaymentBlocker>
                  <TitleTransactions />
                </InvalidPaymentBlocker>
              </PermissionRedirect>
            </TitleAccountOnboardingWrapper>
          }
        >
          <Route
            path="export"
            element={<ExportModal deserializer={mortgageDashboardDeserializer} />}
          />
          <Route
            path="employee/:orgMembershipId"
            element={
              <PermissionRedirect
                permissions={["viewOrganizationDetails", "viewOrganizationTransactions"]}
              >
                <TransactionEmployeeDetails />
              </PermissionRedirect>
            }
          />
          {!transactionDetailsRedesignFlag && (
            // Old transaction details MODAL
            <Route
              path=":transactionID/*"
              element={
                <TransactionDetailsRoutes isTxnDetailsRedesign={transactionDetailsRedesignFlag} />
              }
            />
          )}
        </Route>
        {/* end /transaction/records */}
        <Route path={EASYLINK_DASHBOARD_PATH}>
          <Route
            element={
              <PermissionRedirect permissions={["viewEasyLinks"]}>
                <InvalidPaymentBlocker>
                  {/* BIZ-6166: Remove the need to have <TitleTransactions /> as parent */}
                  <TitleTransactions />
                </InvalidPaymentBlocker>
              </PermissionRedirect>
            }
          >
            <Route
              index
              element={
                <EasylinkManager
                  allowedEasylinkTypes={[EasylinkTypes.IDENTIFY, EasylinkTypes.SIGNER_UPLOAD]}
                />
              }
            />
          </Route>
        </Route>
        <Route
          path={TEMPLATES_DASHBOARD_PATH}
          element={
            <PermissionRedirect permissions={["manageTitleTemplates"]}>
              <InvalidPaymentBlocker>
                <TitleTransactions />
              </InvalidPaymentBlocker>
            </PermissionRedirect>
          }
        >
          <Route index element={<OrganizationTemplates />} />
          <Route path=":templateId/new" element={<OrganizationTemplateEdit isMortgage />} />
          <Route path=":templateId/edit" element={<OrganizationTemplateEdit isMortgage />} />
          <Route path=":templateId/tools" element={<OrganizationTemplateTools />} />
          <Route path=":globalID" element={<OrganizationTemplatePrepare />} />
        </Route>

        {transactionDetailsRedesignFlag && (
          // New transaction details PAGE
          <Route
            path={`${TRANSACTION_PATH}/:transactionID/*`}
            element={
              <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
                <InvalidPaymentBlocker>
                  <TransactionDetailsRoutes isTxnDetailsRedesign={transactionDetailsRedesignFlag} />
                </InvalidPaymentBlocker>
              </PermissionRedirect>
            }
          />
        )}
        <Route
          path="/easylinks/new"
          element={
            <PermissionRedirect permissions={["createEasyLinks"]}>
              <EasylinkCreateForm />
            </PermissionRedirect>
          }
        />
        <Route
          path="/easylinks/edit/:easylinkId"
          element={
            <PermissionRedirect permissions={["updateEasyLinks"]}>
              <EasylinkEditForm />
            </PermissionRedirect>
          }
        />
      </Route>
      <Route
        path="/meetings/*"
        element={
          <RouteWrapper>
            <TitleAccountOnboardingWrapper>
              <ClosingAgenda />
            </TitleAccountOnboardingWrapper>
          </RouteWrapper>
        }
      />

      {makeSetActiveOrgAndRedirectRoute({
        path: "/set-active-org/:orgId",
        wrapper: <RouteWrapper />,
        redirect: <RedirectRoot />,
      })}

      <Route path="*" element={<NavigateWithSearchPreservation to={TRANSACTION_PATH} />} />
    </>
  );
};

function TitleRouter() {
  const isAuthenticated = useIsAuthenticated();
  const isMobileSupportedRoute = useMatchManyFirstPathParts(MOBILE_ROUTES);
  const isTxnDetailsRedesign = useTxnDetailsRedesign("title");
  const firstPathPart = useMatch("/:firstPathPart")?.params.firstPathPart;
  const wrapInApp = (children: ReactNode) => (
    <App isAuthenticated={isAuthenticated} mobileSupported={isMobileSupportedRoute}>
      {children}
    </App>
  );

  if (firstPathPart === "shortcut-cheatsheet") {
    return <ShortcutCheatsheet />;
  } else if (firstPathPart === "set-password") {
    return <SetPassword />;
  } else if (firstPathPart === "access") {
    return wrapInApp(<TitleAccess />);
  } else if (firstPathPart === "verify-email") {
    return wrapInApp(<VerifyEmailV2 />);
  }
  return (
    <TargetAuthLinkHandler>
      {wrapInApp(
        <Routes>
          <Route path="/session_expired" element={<SessionExpiredModal />} />

          <Route path="/verify-account" element={<VerifyEmail />} />

          <Route path="/upgrade" element={<TierUpgrade />} />

          <Route path="/activate/:token" element={<Activation entry={ENTRY} />} />

          {isAuthenticated ? authRoutes(isTxnDetailsRedesign) : UNAUTH_ROUTES}
        </Routes>,
      )}
    </TargetAuthLinkHandler>
  );
}

const ROOT = (
  <ViewerManager>
    <TitleRouter />
  </ViewerManager>
);

export default () => ROOT;
